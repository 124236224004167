<!--
* @Author: tianzl
* @Date: 2024-04-19 10:48:50
* @LastEditors: tianzl
* @LastEditTime: 2024-04-19 10:48:50
* @Description: 大客户订单
 -->
 <template>
    <div class="big-order">
        <div class="header">
            <div class="bigType">大客户</div>
            <div class="follow">交付回访</div>
        </div>
        <div class="content">
            <div v-for="item in fieldList" :key="item.field" class="row">
                <div class="label">{{ item.label }}：</div>
                <div class="value">
                  <span v-if="item.field==='customerName'">{{ orderDetails[item.field] }}</span>
                  <span v-else>{{ orderDetails?.carRegistration[item.field] }}</span>

                </div>
            </div>
        </div>
         <div class="footer">
            <div style="color: #e4002c;width: 150px;">{{ yuqi }}</div>
            <div class="icons">
              <qwIcon
                v-if="orderDetails.customerWeChat"
                :leadId="orderDetails.leadId"
                :externalUserIds="orderDetails.customerWeChat"
                class="icon"
              />
              <phoneIcon
                  :phone="orderDetails?.carRegistration.mobile"
                  class="icon"
              />
              <img
                  width="32px"
                  height="32px"
                  class="ml-16"
                  :src="require('@/images/follow@2x.png')"
                  @click.stop="goFollow"
              />
            </div>
         </div>
    </div>
 </template>
<script>
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
export default {
  components: { externalUserIcon,qwIcon,wxIcon,phoneIcon },
  props:{
    orderDetails: {
      type: Object,
      default: ()=>({})
    },
    relationId: {
      type: String,
      default: ''
    },
    yuqi:{
      type: String,
      default: ''
    }
  },
  data(){
    return {
      fieldList: [
        { label:'购车人',field: 'customerName' },
        { label:'联系人',field: 'contactPerson' },
        { label:'联系方式',field: 'mobile' },
      ]
    }
  },
  mounted(){
    console.log(this.orderDetails,'orderDetails')

  },
  methods: {
    selectUser(params = {}) {
      const { externalUserid, name } = params
      this.$set(this.orderDetails, 'customerName', name)
      this.$set(this.orderDetails, 'customerWeChat', externalUserid)
    },
    goFollow(){
      this.$router.push({
        path:  '/order-follow',
        query: {
          id: this.relationId,
          orderId: this.relationId,
          taskCode:'DELIVERY_VISIT', // 交车回访
        },
      })
    }
  }
}
</script>
 <style lang="less" scoped>
.big-order{
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 16px 12px 0px 12px;
    font-size: 14px;
    .header{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(13, 23, 26, 0.05);
        padding-bottom: 12px;
        .bigType{
          display: inline-block;
          height: 22px;
          line-height: 22px;
          padding: 0 8px;
          color: #fff;
          margin-left: 8px;
          background: #029640;
        }
        .follow{
            color: #029640;
        }
    }
    .content{
        font-size: 13px;
        line-height: 22px;
        padding: 12px 0;
        .row{
          display: flex;
        }
        .label{
            color:rgba(13, 23, 26, 0.45);
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(13, 23, 26, 0.05);
        padding: 8px 0;

        .icons{
          display: flex;
        }
        
        .icon {
            margin-left: 16px;
        }

        .ml-16{
            margin-left: 16px;
        }
    }
}
</style>