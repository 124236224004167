import { render, staticRenderFns } from "./bigOrderItem.vue?vue&type=template&id=125c542b&scoped=true"
import script from "./bigOrderItem.vue?vue&type=script&lang=js"
export * from "./bigOrderItem.vue?vue&type=script&lang=js"
import style0 from "./bigOrderItem.vue?vue&type=style&index=0&id=125c542b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125c542b",
  null
  
)

export default component.exports