var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"to-do-list"},[_c('van-dropdown-menu',{attrs:{"active-color":"#B9921A"}},[_c('van-dropdown-item',{attrs:{"options":_vm.taskData.options},on:{"change":_vm.onRefresh},model:{value:(_vm.taskCode),callback:function ($$v) {_vm.taskCode=$$v},expression:"taskCode"}}),_c('van-dropdown-item',{ref:"dropdownTime",attrs:{"title":`${
          _vm.$moment().isSame(_vm.start, 'day')
            ? _vm.$t('今天')
            : _vm.$moment(_vm.start).format('YYYY-MM-DD')
        }`,"disabled":""}},[_c('van-calendar',{staticStyle:{"height":"394px"},attrs:{"poppable":false,"show-title":false,"type":"range","first-day-of-week":1,"allow-same-day":"","show-confirm":false,"default-date":[new Date(_vm.$moment(_vm.start)), new Date(_vm.$moment(_vm.end))]},on:{"confirm":_vm.onConfirm}})],1)],1),_c('p',{staticClass:"total"},[_vm._v(_vm._s(_vm.$t("共"))+" "+_vm._s(_vm.totalCount)+" "+_vm._s(_vm.$t("条数据")))]),(_vm.$store.state.user.userInfo.id)?_c('List',{ref:"list",attrs:{"fetchApi":_vm.fetchApi},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [(_vm.isForLead(item))?_c('div',{staticClass:"item-wrap",on:{"click":function($event){return _vm.getPath(item)}}},[_c('div',{staticClass:"user-info"},[_c('ShortConsumerInfo',{attrs:{"data":item.lead}},[_c('span',{staticStyle:{"margin-left":"auto","color":"#FFD100","font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.taskData.nameConfig[item.taskCode])+" ")])])],1),_c('CardOptions',{attrs:{"data":item.lead}}),_c('div',{staticClass:"mb-8"},[_c('span',[_vm._v(_vm._s(_vm.$t("用户来源"))+"：")]),_c('span',[_vm._v(_vm._s(_vm._f("sourceText")(item.lead)))])]),_c('div',{staticClass:"van-ellipsis mb-8"},[_c('span',[_vm._v(_vm._s(_vm.$t("跟进人"))+"：")]),_c('span',[_vm._v(_vm._s(item.lead.followUserName || "--"))]),(item.lead.followRemark)?[_c('span',[_vm._v(" | ")]),_c('span',[_vm._v(_vm._s(item.lead.followRemark))])]:_vm._e()],2),_c('div',{staticClass:"actions"},[(_vm.$moment().diff(item.planTime, 'hours') > 0)?_c('span',{staticClass:"yuqi"},[_vm._v(" "+_vm._s(_vm.getYuQiTime(item.planTime))+" ")]):_vm._e(),_c('span',{directives:[{name:"btn-premission",rawName:"v-btn-premission",value:(['BTN-CLUE-DISTRIBUTE']),expression:"['BTN-CLUE-DISTRIBUTE']"}],staticClass:"assigned",on:{"click":function($event){$event.stopPropagation();return (() => {
                _vm.distributionRole = item.lead.followUserRoleCode;
                _vm.showDistribution = true;
                _vm.distributionIds = [item.leadId];
              }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("重新分配"))+" ")]),(item.lead.userWeChat)?_c('QwIcon',{staticClass:"ml-16",attrs:{"externalUserIds":item.lead.userWeChat}}):_vm._e(),_c('PhoneIcon',{staticClass:"ml-16",attrs:{"phone":item.lead.userMobile,"oneself":item.lead.followUserId === _vm.$store.state.user.userInfo.id,"clueId":item.lead.id}}),_c('img',{staticClass:"ml-16",attrs:{"width":"32px","src":require('@/images/follow@2x.png')},on:{"click":function($event){$event.stopPropagation();return _vm.getPath(item, true)}}})],1)],1):(_vm.isForOrder(item))?[(
            item?.lead?.orders?.length && item.lead.orders[0].orderType !== 1
          )?_c('OrderItem',{staticClass:"order",attrs:{"dataSource":item.order}},[_c('span',{staticClass:"todo-actions",attrs:{"slot":"btns"},slot:"btns"},[(_vm.$moment().diff(item.planTime, 'hours') > 0)?_c('span',{staticClass:"yuqi"},[_vm._v(" "+_vm._s(_vm.getYuQiTime(item.planTime))+" ")]):_vm._e(),_c('span',{directives:[{name:"btn-premission",rawName:"v-btn-premission",value:(['BTN-CLUE-DISTRIBUTE']),expression:"['BTN-CLUE-DISTRIBUTE']"}],staticClass:"assigned",on:{"click":function($event){$event.stopPropagation();return (() => {
                  _vm.distributionRole = item.lead.followUserRoleCode;
                  _vm.showDistribution = true;
                  _vm.distributionIds = [item.leadId];
                }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("重新分配"))+" ")]),_c('PhoneIcon',{staticClass:"ml-16",attrs:{"phone":item.lead.userMobile,"oneself":item.lead.followUserId === _vm.$store.state.user.userInfo.id,"clueId":item.lead.id}}),_c('img',{staticClass:"ml-16",attrs:{"width":"32px","src":require('@/images/follow@2x.png')},on:{"click":function($event){$event.stopPropagation();return _vm.getPath(item, true)}}})],1)]):_vm._e(),(
            item?.lead?.orders?.length && item.lead.orders[0].orderType === 1
          )?_c('BigOrderItem',{attrs:{"orderDetails":item.lead.orders[0],"relationId":item.relationId,"yuqi":_vm.getYuQiTime(item.planTime)}}):_vm._e()]:(_vm.isForStorePdc(item))?_c('PdcCard',{staticClass:"card",attrs:{"data":item.appointment,"is-todo":true}},[_c('span',{staticClass:"todo-actions",attrs:{"slot":"btns"},slot:"btns"},[_c('span',{directives:[{name:"btn-premission",rawName:"v-btn-premission",value:(['BTN-CLUE-DISTRIBUTE']),expression:"['BTN-CLUE-DISTRIBUTE']"}],staticClass:"assigned",staticStyle:{"vertical-align":"text-bottom"},on:{"click":function($event){$event.stopPropagation();return (() => {
                _vm.distributionRole = item.lead.followUserRoleCode;
                _vm.showDistribution = true;
                _vm.distributionIds = [item.leadId];
              }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("重新分配"))+" ")]),_c('PhoneIcon',{staticClass:"ml-16",attrs:{"phone":item.lead.userMobile,"oneself":item.lead.followUserId === _vm.$store.state.user.userInfo.id,"clueId":item.lead.id}}),_c('img',{staticClass:"ml-16",attrs:{"width":"32px","src":require('@/images/follow@2x.png')},on:{"click":function($event){$event.stopPropagation();return _vm.getPath(item, true)}}})],1)]):(_vm.isForAppointment(item))?_c('Card',{staticClass:"card",attrs:{"data":item.appointment,"is-todo":true,"yuqi":_vm.$moment().diff(item.planTime, 'hours') > 0
            ? _vm.getYuQiTime(item.planTime)
            : ''}},[_c('span',{staticClass:"todo-actions",attrs:{"slot":"btns"},slot:"btns"},[_c('span',{directives:[{name:"btn-premission",rawName:"v-btn-premission",value:(['BTN-CLUE-DISTRIBUTE']),expression:"['BTN-CLUE-DISTRIBUTE']"}],staticClass:"assigned",staticStyle:{"vertical-align":"text-bottom"},on:{"click":function($event){$event.stopPropagation();return (() => {
                _vm.distributionRole = item.lead.followUserRoleCode;
                _vm.showDistribution = true;
                _vm.distributionIds = [item.leadId];
              }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("重新分配"))+" ")]),_c('PhoneIcon',{staticClass:"ml-16",attrs:{"phone":item.lead.userMobile,"oneself":item.lead.followUserId === _vm.$store.state.user.userInfo.id,"clueId":item.lead.id}}),_c('img',{staticClass:"ml-16",attrs:{"width":"32px","src":require('@/images/follow@2x.png')},on:{"click":function($event){$event.stopPropagation();return _vm.getPath(item, true)}}})],1)]):_vm._e()]}}],null,false,3735604426)}):_vm._e(),_c('BusinessFilters',{ref:"filter",attrs:{"options":[
      {
        name: '',
        type: 'B_PEOPLE_SELECTION',
        field: 'saIdList',
        isSelection: true,
        valueType: 'object',
        shopIds: '',
        height: '80vh',
        roleCodes: _vm.distributionRole,
      },
    ],"defaultActiveKey":['saIdList'],"buttonNameReset":_vm.$t('取消')},on:{"reset":function($event){_vm.showDistribution = false},"submit":_vm.distributionFilter},model:{value:(_vm.showDistribution),callback:function ($$v) {_vm.showDistribution=$$v},expression:"showDistribution"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }